import "can-construct-super"
import Control from "can-control"

import 'bootstrap/dist/css/bootstrap.css'
import '@/icons/font/fontello.css'

import '@/fonts/ProximaNova/stylesheet.css'

//before
import '@common/app.min.css'
import '@common/mob_app.min.css'

import '@components/swiper.scss'

//
import '@components/header.scss' 
import '@components/menu.scss'
import '@components/footer.scss'

import $ from "jquery";

import {SlideoutPanel} from "@/js/controllers/slideout-panel.js";
import {ElementStick} from "@/js/controllers/element-stick"

import "swiped-events"

/**
 * Базовый контроллер страницы
 * @type {void | *}
 */
const BasePage = Control.extend({
	defaults: {}
}, {
	init() {
		new SlideoutPanel(document.querySelector(`body`))

		$(window).scroll(function() {
			ElementStick($(".site-header"), "100%", $(window).scrollTop(), $(".site-header").height(), 0);
			$("#shape").height($(document).height());
		})

		$('.nav-item.dropdown').mouseenter(function () {
			$('#shape').addClass('in-active');
		});
		$('.nav-item.dropdown').mouseleave(function () {
			$('#shape').removeClass('in-active');
		});
		
	}
})

export {BasePage}
